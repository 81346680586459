import cssVars from 'css-vars-ponyfill';

cssVars({
	variables: {
		'primary': '49, 27, 146',
		'primary-dark': '0, 0, 99',
		'accent': '0, 200, 83',
		'accent-plus': '7, 7, 7',
	},
});
